import { arc, pie, line, curveNatural, curveStepAfter, curveLinear, curveStepBefore, curveCardinal, curveMonotoneX } from "d3-shape";
import { scaleOrdinal, scaleLinear, scaleTime, scaleBand } from "d3-scale";
import { format } from "d3-format";
import { timeSecond, timeMinute, timeHour, timeDay, timeMonth, timeWeek, timeYear, utcYear, utcDay } from "d3-time";
import { timeFormat, timeParse, timeFormatLocale, timeFormatDefaultLocale } from "d3-time-format";
import { select, selectAll } from "d3-selection";
import { rgb } from "d3-color";

import { max, min, extent, bisectLeft, histogram, group, groups, ascending, descending, rollup, range } from "d3-array";
import { axisTop, axisBottom, axisLeft, axisRight } from "d3-axis";
import { zoom, zoomTransform, zoomIdentity } from "d3-zoom";
import { brushX, brush } from "d3-brush";
import { transition } from "d3-transition";
import { easeLinear } from "d3-ease";
import { create, version } from "d3";
import { quadtree } from 'd3-quadtree';
import { forceSimulation, forceX, forceY, forceCollide, forceManyBody } from 'd3-force';

timeFormatDefaultLocale(
  {
    "dateTime": "%A den %d %B %Y %X",
    "date": "%Y-%m-%d",
    "time": "%H:%M:%S",
    "periods": ["fm", "em"],
    "days": ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
    "shortDays": ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
    "months": ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"]
  }
);

export default {
  arc,
  pie,
  line,
  scaleTime,
  scaleLinear,
  scaleOrdinal,
  scaleBand,
  select,
  selectAll,
  min,
  max,
  extent,
  axisTop,
  axisBottom,
  axisLeft,
  axisRight,
  curveLinear,
  curveNatural,
  curveStepAfter,
  curveStepBefore,
  curveMonotoneX,
  curveCardinal,
  bisectLeft,
  format,
  timeFormat,
  timeFormatLocale,
  timeFormatDefaultLocale,
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeMonth,
  timeWeek,
  timeParse,
  timeYear,
  zoom,
  zoomTransform,
  zoomIdentity,

  histogram,
  group,
  groups,
  create,
  version,
  ascending,
  descending,
  rollup,
  transition,
  easeLinear,
  brushX,
  brush,
  utcYear,
  utcDay,

  rgb,
  quadtree,
  range,
  forceSimulation,
  forceX,
  forceY,
  forceCollide,
  forceManyBody
};
